import React, { useRef, useState } from "react"
import { IPackResult } from "../packer"

interface Props {
	result: IPackResult
	onStartOver: () => void
}

export const Result: React.FC<Props> = ({ result, onStartOver }) => {
	const [previewFullSize, setPreviewFullSize] = useState<boolean>(false)

	const containerRef = useRef<HTMLDivElement>(null)

	let containerScale = 1
	if (previewFullSize && containerRef.current) {
		containerScale = containerRef.current.clientWidth / result.width

		if (containerRef.current.clientWidth >= 768) {
			containerScale /= 3
		}
	}

	return (
		<>
			<p>
				Total length: {result.height} inches or {Math.floor(result.height / 12)}' {result.height % 12}"
			</p>

			{result.missed.length > 0 && (
				<p>
					Pieces that didn't fit:{" "}
					{result.missed.map(({ width, height }) => `${width} x ${height}`).join(", ")}
				</p>
			)}

			<div className="d-print-none d-grid d-lg-flex gap-2 mb-3">
				<button
					type="button"
					className="btn btn-secondary"
					onClick={(event) => {
						event.preventDefault()
						onStartOver()
					}}
				>
					Go Back
				</button>

				<button
					type="button"
					className="btn btn-primary"
					onClick={(event) => {
						event.preventDefault()
						setPreviewFullSize(!previewFullSize)
					}}
				>
					Toggle Preview Size
				</button>
			</div>

			<div ref={containerRef}>
				<div
					className="preview-container bg-light mb-3"
					style={{ width: result.width * containerScale, height: result.height * containerScale }}
				>
					{result.nodes.map((node, i) => (
						<div
							key={i}
							className="preview-item bg-white"
							style={{
								width: node.width * containerScale,
								height: node.height * containerScale,
								left: node.x * containerScale,
								top: node.y * containerScale,
							}}
						>
							{previewFullSize && (
								<span>
									{node.width} x {node.height}
								</span>
							)}
						</div>
					))}
				</div>
			</div>
		</>
	)
}
