export interface IBox {
	width: number
	height: number
}

export interface IPackResult {
	usedArea: number
	width: number
	height: number
	nodes: INode[]
	missed: IBox[]
}

interface INode extends IBox {
	x: number
	y: number
	down?: INode
	right?: INode
}

export class Packer {
	height?: number
	width: number
	spacing: number

	boxSorters = [
		(a: IBox, b: IBox) => (a.height > b.height ? -1 : 1),
		(a: IBox, b: IBox) => (a.width > b.width ? -1 : 1),
		(a: IBox, b: IBox) => (a.width * a.height > b.width * b.height ? -1 : 1),
	]

	constructor({ width, height, spacing }: { width: number; height?: number; spacing: number }) {
		if (height !== undefined) {
			this.height = Math.max(height, width)
			this.width = Math.min(height, width)
		} else {
			this.width = width
		}

		this.spacing = spacing
	}

	pack(boxes: IBox[]) {
		const rotatedBoxesOptions = this.generateRotatedBoxes(boxes)

		const results = this.boxSorters.reduce<IPackResult[]>((results, sorter) => {
			for (let i = 0; i < rotatedBoxesOptions.length; i++) {
				rotatedBoxesOptions[i].sort(sorter)

				const result = this.actuallyPack(rotatedBoxesOptions[i])

				if (result) {
					results.push(result)
				} else {
					console.log("Failed to pack", sorter)
				}
			}

			return results
		}, [])

		results.sort((a, b) => (a.missed.length < b.missed.length || a.usedArea < b.usedArea ? -1 : 1))

		return results[0]
	}

	private generateRotatedBoxes(boxes: IBox[]) {
		let options: IBox[][] = [[]]

		const alignedBoxes = boxes.map(({ width, height }) =>
			width <= this.width && (this.height === undefined || height <= this.height)
				? {
						width,
						height,
				  }
				: {
						width: height,
						height: width,
				  }
		)

		options.push(alignedBoxes)
		for (let i = 0; i < 5000; i++) {
			options.push(
				alignedBoxes.map(({ width, height }) =>
					height <= this.width && (this.height === undefined || width <= this.height) && Math.random() < 0.5
						? {
								width: height,
								height: width,
						  }
						: {
								width,
								height,
						  }
				)
			)
		}

		/*
		for (let i = 0; i < boxes.length; i++) {
			const prevOptions = options
			const currentBox = boxes[i]

			options = []

			for (let j = 0; j < prevOptions.length; j++) {
				options.push([...prevOptions[i], currentBox])
			}

			if (currentBox.width !== currentBox.height) {
				const flippedBox = {
					width: currentBox.width,
					height: currentBox.height,
				}

				for (let j = 0; j < prevOptions.length; j++) {
					options.push([...prevOptions[i], flippedBox])
				}
			}
		}
		*/

		/*
		options = [alignedBoxes]
        */

		return options
	}

	private actuallyPack(boxes: IBox[]) {
		const root = { x: 0, y: 0, width: this.width, height: this.height ?? Number.MAX_SAFE_INTEGER }
		const nodes: INode[] = []
		const missed: IBox[] = []

		for (let i = 0; i < boxes.length; i++) {
			const { width, height } = boxes[i]
			const node = this.findNode(root, width, height)

			if (!node) {
				missed.push(boxes[i])
			} else {
				nodes.push({ ...node, width, height })
				this.splitNode(node, width, height)
			}
		}

		const usedWidth = Math.max(...nodes.map((node) => node.x + node.width))
		const usedHeight = Math.max(...nodes.map((node) => node.y + node.height))
		const usedArea = usedWidth * usedHeight

		return { nodes, missed, usedArea, width: root.width, height: this.height ?? usedHeight }
	}

	private findNode(root: INode, width: number, height: number): INode | null {
		return (
			(root.right && this.findNode(root.right, width, height)) ||
			(root.down && this.findNode(root.down, width, height)) ||
			(!root.right && !root.down && width <= root.width && height <= root.height && root) ||
			null
		)
	}

	private splitNode(node: INode, width: number, height: number) {
		node.down = {
			x: node.x,
			y: node.y + height + this.spacing,
			width: node.width,
			height: node.height - height - this.spacing,
		}

		node.right = {
			x: node.x + width + this.spacing,
			y: node.y,
			width: node.width - width - this.spacing,
			height: height,
		}

		return node
	}
}
