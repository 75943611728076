import React, { useState } from "react"
import classNames from "classnames"
//import { DEMO_DATA } from "../data"
import { IBox } from "../packer"
import { IPiece } from "../types"

const EMPTY_PIECE: IPiece = { quantity: "", height: "", width: "" }

/*
const DEFAULT_PIECES = DEMO_DATA.map(({ quantity, height, width }) => ({
	quantity: String(quantity),
	height: String(height),
	width: String(width),
}))
*/

const DEFAULT_PIECES = Array.from(new Array(10)).map(() => ({ ...EMPTY_PIECE }))

const copyPieces = (pieces: IPiece[]) => pieces.map((piece) => ({ ...piece }))

interface Props {
	onSubmit: (data: { width: number; height?: number; spacing: number; boxes: IBox[] }) => void
}

export const Inputs: React.FC<Props> = ({ onSubmit }) => {
	const [strRollSize, setRollSize] = useState<string>("60")
	const [strRollMaxLength, setRollMaxLength] = useState<string>("")
	const [strSpaceBetweenPieces, setSpaceBetweenPieces] = useState<string>("1")
	const [pieces, setPieces] = useState<IPiece[]>(copyPieces(DEFAULT_PIECES))
	const [submitting, setSubmitting] = useState<boolean>(false)
	const [hasErrors, setErrors] = useState<boolean>(false)

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault()

		if (submitting) {
			return
		}

		if (
			pieces.some(
				(piece) =>
					(piece.quantity || piece.width || piece.height) &&
					(isNaN(parseInt(piece.quantity)) || isNaN(parseInt(piece.width)) || isNaN(parseInt(piece.height)))
			)
		) {
			setErrors(true)
			return
		}

		setSubmitting(true)
		setErrors(false)

		console.log(JSON.stringify(pieces))

		const boxes = pieces
			.filter((piece) => piece.quantity)
			.reduce<IBox[]>(
				(boxes, piece) =>
					boxes.concat(
						Array.from(new Array(parseInt(piece.quantity))).map(() => ({
							width: parseInt(piece.width),
							height: parseInt(piece.height),
						}))
					),
				[]
			)

		onSubmit({
			width: parseInt(strRollSize),
			height: strRollMaxLength !== "" ? parseInt(strRollMaxLength) : undefined,
			spacing: strSpaceBetweenPieces !== "" ? parseInt(strSpaceBetweenPieces) : 0,
			boxes,
		})

		setSubmitting(false)
	}

	return (
		<form className="d-print-none" onSubmit={handleSubmit}>
			<div className="row">
				<div className="col-xs-12 col-sm-4 col-md-3 mb-3">
					<label className="form-label">Roll Size</label>
					<div className="input-group">
						<input
							type="text"
							inputMode="numeric"
							pattern="([0-9]*\.)?[0-9]+"
							className="form-control"
							disabled
							value={strRollSize}
							onChange={(event) => setRollSize(event.target.value)}
						/>
						<div className="input-group-text">in.</div>
					</div>
				</div>
				{/*
                <div className="col-xs-12 col-sm-4 col-md-3 mb-3">
                    <label className="form-label">Roll Max Length</label>
                    <div className="input-group">
                        <input
                            type="text"
                            inputMode="numeric"
                            pattern="([0-9]*\.)?[0-9]+"
                            placeholder="(optional)"
                            className="form-control"
                            disabled
                            value={strRollMaxLength}
                            onChange={(event) => setRollMaxLength(event.target.value)}
                        />
                        <div className="input-group-text">in.</div>
                    </div>
                </div>
                */}
				<div className="col-xs-12 col-sm-4 col-md-3 mb-3">
					<label className="form-label">Space Between Pieces</label>
					<div className="input-group">
						<input
							type="text"
							inputMode="numeric"
							pattern="([0-9]*\.)?[0-9]+"
							placeholder="(optional)"
							className="form-control"
							disabled
							value={strSpaceBetweenPieces}
							onChange={(event) => setSpaceBetweenPieces(event.target.value)}
						/>
						<div className="input-group-text">in.</div>
					</div>
				</div>
			</div>

			<h3>Pieces</h3>

			<p>
				<em>Dimensions are expected to be inches.</em>
			</p>

			{pieces.map((piece, i) => (
				<div key={i} className="row mb-3">
					<div className="col-3">
						{i === 0 && <label className="form-label">Quantity</label>}
						<input
							type="text"
							inputMode="numeric"
							pattern="([0-9]*\.)?[0-9]+"
							className={classNames("form-control", {
								"border border-danger":
									(piece.height || piece.width) && isNaN(parseInt(piece.quantity)),
							})}
							value={piece.quantity}
							onChange={(event) => {
								piece.quantity = event.target.value
								setPieces([...pieces])
							}}
						/>
					</div>
					<div className="col-3">
						{i === 0 && <label className="form-label">Height</label>}
						<input
							type="text"
							inputMode="numeric"
							pattern="([0-9]*\.)?[0-9]+"
							className={classNames("form-control", {
								"border border-danger":
									(piece.quantity || piece.width) && isNaN(parseInt(piece.height)),
							})}
							value={piece.height}
							onChange={(event) => {
								piece.height = event.target.value
								setPieces([...pieces])
							}}
						/>
					</div>
					<div className="col-3">
						{i === 0 && <label className="form-label">Width</label>}
						<input
							type="text"
							inputMode="numeric"
							pattern="([0-9]*\.)?[0-9]+"
							className={classNames("form-control", {
								"border border-danger":
									(piece.height || piece.quantity) && isNaN(parseInt(piece.width)),
							})}
							value={piece.width}
							onChange={(event) => {
								piece.width = event.target.value
								setPieces([...pieces])
							}}
						/>
					</div>
					<div className="col-3">
						{i === 0 && <label className="form-label">&nbsp;</label>}
						<div className="d-grid">
							<button
								type="button"
								className="btn btn-light"
								onClick={(event) => {
									event.preventDefault()

									pieces.splice(i, 1)

									if (!pieces.length) {
										pieces.push({ ...EMPTY_PIECE })
									}

									setPieces([...pieces])
								}}
							>
								X
							</button>
						</div>
					</div>
				</div>
			))}

			<div className="d-grid d-lg-flex gap-2 mb-3">
				<button
					type="button"
					className="btn btn-light"
					onClick={(event) => {
						event.preventDefault()

						pieces.push({ ...EMPTY_PIECE })
						setPieces([...pieces])
					}}
				>
					Add another piece
				</button>

				<button
					type="button"
					className="btn btn-light"
					onClick={(event) => {
						event.preventDefault()

						setPieces(copyPieces(DEFAULT_PIECES))
					}}
				>
					Reset
				</button>
			</div>

			<hr />

			{hasErrors && (
				<div className="alert alert-danger">
					Some of your inputs are invalid. Check the ones outlined in red.
				</div>
			)}

			<div className="d-grid d-lg-block">
				<button type="submit" className="btn btn-primary">
					Generate Pack
				</button>
			</div>
		</form>
	)
}
