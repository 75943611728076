import ReactDOM from "react-dom"
import { Create } from "./views/Create"
import "./css/main.scss"

ReactDOM.render(
	<div className="container-md py-3">
		<Create />
	</div>,
	document.getElementById("root")
)
