import classNames from "classnames"
import React, { useState } from "react"
import { IPackResult, Packer } from "../packer"
import { Inputs } from "./Inputs"
import { Result } from "./Result"

export const Create: React.FC = () => {
	const [result, setResult] = useState<IPackResult>()

	return (
		<>
			<h1 className="d-print-none">Window Film Quote</h1>

			<div className={classNames({ "d-none": result })}>
				<Inputs
					onSubmit={({ width, height, spacing, boxes }) => {
						const packer = new Packer({ width, height, spacing })

						const result = packer.pack(boxes)

						setResult(result)
					}}
				/>
			</div>

			{result && <Result result={result} onStartOver={() => setResult(undefined)} />}
		</>
	)
}
